
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap

import Document from "flexsearch/dist/module/document";
window.fsdoc = Document

import ready from './utilities/ready';

import invokeKeenSlider from './components/keen-slider';
import invokeColorPicker from './components/colorpicker';
import invokeDoubleClick from './components/double-click';
//import invokeStickyHeader from './components/sticky-header';
import invokeScrollToTop from './components/to-top';

ready(invokeKeenSlider);
ready(invokeColorPicker);
ready(invokeDoubleClick);
//ready(invokeStickyHeader);
ready(invokeScrollToTop);
